<template>
  <div>
    <header class="bg-app-400 dark:bg-app-400 p-4">
      <div class="flex flex-row">
        <span class="uppercase text-white font-medium basis-3/4">Ranking</span>
        <span v-if="loading == true" class="uppercase text-white text-xs basis-1/4 text-right">atualizando...</span>
        <span v-else class="uppercase text-white text-xs font-medium basis-1/4 text-right">{{ timer }}</span>
      </div>
    </header>

    <div>
      <div v-if="ranking.length === 0">
        <div class="text-center">
          <p class="alert-text text-center">Não há compeditores cadastrados</p>
        </div>
      </div>

      <div v-else class="relative overflow-x-auto shadow-md sm:rounded-lg">

        <table class="w-full text-sm text-center text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-300 dark:bg-gray-600 dark:text-gray-400">
            <tr class="border-b dark:border-gray-700">
              <th scope="row" class="px-4 py-4">
                PESO TOTAL
              </th>
              <th scope="row" class="px-4 py-4">
                PEIXES TOTAIS
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="border-b dark:border-gray-700">
              <td scope="row" class="px-4 py-4">
                {{ totais.total_pesado }}kg                
              </td>
              <td scope="row" class="px-4 py-4">
                {{ totais.total_peixes }}
              </td>
            </tr>
          </tbody>
        </table>
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-300 dark:bg-gray-600 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-4 py-3">
                Equipe
              </th>
              <th scope="col" class="px-4 py-3">
                Peso
              </th>
            </tr>
          </thead>

          <tbody>
            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-200 even:dark:bg-gray-800 border-b dark:border-gray-700" v-for="(equipe, index) in ranking" :key="index">
              <td scope="row" class="px-4 py-4">
                {{ equipe.posicao + '. ' + equipe.competidores }}
              </td>
              <td scope="row" class="px-4 py-4">
                {{ equipe.peso }}kg
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { ClockIcon } from '@heroicons/vue/24/outline';

export default {
  name: "RankingView",
  methods: {
    async loadRanking() {
      console.log("*** loadRanking ***");
      this.loading = true;
      this.$axios.get(
        this.$root.serverAddress +
        "ranking.php?serialColetor=" +
        this.$root.deviceSerial +
        "&etapaId=" +
        this.$root.currentStage
      ).then((response) => {
        this.loading = false;
        this.ranking = response.data.ranking;
        this.totais = response.data.totais;
        console.log(this.ranking);
      }).catch((error) => {
        return {success:false, status:error.response.status};
      });
    },
    updateRanking() {
      this.timer--;
      if(this.timer < 0) {
        this.timer = 5;
        this.loadRanking();
      }
    }

  },
  mounted() {
    if (
      this.$root.appStatus.db &&
      this.$root.appStatus.parameters &&
      this.$root.appStatus.data &&
      this.$root.appStatus.championship
    ) {
      this.loadRanking();
      this.update = setInterval(this.updateRanking, 1000);
    } else {
      this.$router.push('/');
    }
  },
  unmounted(){
   clearInterval(this.update);
  },
  components: {
    ClockIcon
  },
  data: () => ({
    update: null,
    timer: 5,
    loading: false,
    ranking: [],
    totais: {},
  }),

};
</script>