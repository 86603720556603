<template>
  <div>
    <header class="bg-app-400 p-4">
      <span class="uppercase text-white font-medium">Parâmetros</span>
    </header>

    <div class="parameters flex flex-col flex-wrap p-4">
      <div v-if="pageReady">
        <div class="mb-4">
          <label class="block mb-2">Serial do Coletor</label>
          <input name="serialColetor" type="text" class="form-control" v-model="$root.deviceSerial" />
        </div>

        <div class="mb-4">
          <label class="block mb-2">Servidor</label>
          <input name="urlAPI" type="text" class="form-control" v-model="$root.serverAddress" />
        </div>

        <button class="block w-full text-white text-center p-4 rounded-md uppercase
                bg-app-400 dark:bg-app-400/40  transition-colors duration-500"
          @click="saveParameters()">Salvar</button>
      </div>
      <div v-else>
        <p>{{ statusInfo }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParametersView",
  methods: {
    async saveParameters() {
      let checkPassword = await this.$root.askPassword();
      if (checkPassword == true) {
        if (this.$root.deviceSerial.length == 0 || this.$root.serverAddress.length == 0) {
          alert("Por favor, preencha os dados corretamente");
        } else {
          this.$root.appStatus.parameters = true;
          console.log("*** saveParameters ***");
          let store = this.$root.db
            .transaction("parameters", "readwrite")
            .objectStore("parameters");
          if(this.$root.serverAddress.slice(-1) != "/") this.$root.serverAddress += "/";
          store.put({ value: this.$root.serverAddress }, "serverAddress");
          store.put({ value: this.$root.deviceSerial }, "deviceSerial");
          alert("Os dados foram salvos com sucesso");
          this.$router.push({ path: "/syncData" });
        }
      }
    },
    goAhead() {
      if (this.pageReady) return false;
      console.log("goAhead Parameters");
      if (this.$root.deviceSerial == false) this.$root.deviceSerial = "";
      if (this.$root.serverAddress == false) this.$root.serverAddress = "";
      this.pageReady = true;
    },
  },
  mounted() {
    if (this.$root.appStatus.db) {
      this.goAhead();
    } else {
      this.$router.push('/');
    }
  },
  /*
  watch: {
    "$root.appStatus": {
      handler: "goAhead",
      deep: true,
    },
  },
  */
  data: () => ({
    pageReady: false,
    statusInfo: "",
  }),
};
</script>

<style lang="postcss">

</style>