<template>
  <div class="home">

    <div class="flex flex-col justify-evenly home__wrapper">

      <div v-if="!$root.appStatus.parameters">
        <div class="text-center p-4 justify-center">
          <p class="alert-text text-center">Os parâmetros de acesso não estão definidos</p>
          <div class="mb-4">
            <p class="text-sm text-center">Clique na opção<br />"PARÂMETROS"<br />no menu do app</p>
          </div>
        </div>
      </div>

      <div v-else-if="!$root.appStatus.data">
        <div class="text-center">
          <p class="alert-text text-center">Os dados do servidor precisam ser sincronizados</p>
          <div class="mb-4">
            <p class="text-sm text-center">Clique na opção<br />"SINCRONIZAR DADOS"<br />no menu do app</p>
          </div>
        </div>
      </div>

      <div v-else-if="!$root.appStatus.championship">
        <div class="text-center">
          <p class=" text-app-400 dark:text-white uppercase">Campeonato e Etapa não foram selecionados</p>
          <div class="mt-4">
            <p class="dark:text-app-200">Clique na opção <span
                class="block text-app-400 dark:text-white  uppercase">"Selecionar
                Campeonato"</span> no
              menu
              do app</p>
          </div>
        </div>
      </div>

      <div v-else>

        <div class="flex flex-wrap gap-4 text-center p-4 justify-center">
          <img :src="'data:image/png;base64,' + $root.currentChampionshipData.imagem"
            class="max-w-[200px] mx-auto object-contain">
          <h2 class="w-full uppercase block text-2xl font-semibold text-app-900 dark:text-slate-200">
            {{ $root.currentChampionshipData.descricao }}
          </h2>
          <span class="w-full uppercase block">{{ $root.currentStageData.descricao }}</span>
          <span class="w-full uppercase block font-bold">{{ $root.currentStageData.fiscal_nome }}</span>
        </div>

        <section class="grid gap-4 grid-cols-2 p-4 items-center">
          <router-link to="/qrCode"
            class="bg-app-400 dark:bg-app-400/40 text-slate-200 text-center px-4 py-8 rounded-md uppercase transition-colors duration-500">
            Ler Pulseira
          </router-link>
          <router-link to="/registerFish"
            class="bg-app-400 dark:bg-app-400/40 leading-[1.75] text-slate-200 text-center p-4 rounded-md uppercase transition-colors duration-500">
            Registro Manual
          </router-link>
          <router-link to="/ranking"
            class="bg-app-400 dark:bg-app-400/40 text-slate-200 text-center px-4 py-8 rounded-md uppercase transition-colors duration-500">
            Ranking
          </router-link>
          <router-link to="/weighings"
            class="bg-app-400 dark:bg-app-400/40 text-slate-200 text-center px-4 py-8 rounded-md uppercase transition-colors duration-500">
            Lançamentos
          </router-link>
        </section>

      </div>

    </div>
  </div>
</template>

<script>

import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';

export default {
  name: "HomeView",
  methods: {
  },
  mounted() {
  },
  components: {
    ExclamationCircleIcon
  },
};
</script>

<style>
.home__wrapper {
  height: calc(100vh - 80px);
}
</style>