<template>

	<button class="burger h-12 w-12 space-y-1 z-20" type="button" :class="{ isActive: isActive }"
		@click="isActive = !isActive">
		<Bars3Icon class="h-9 w-9 stroke-white mx-auto" />
	</button>

	<ul
		class="menu bg-app-400 dark:bg-app-600 fixed overflow-y-scroll w-full h-screen left-0 z-10 top-0 pt-20 transition-all duration-300"
		:class="{ isActive: isActive }" @click="isActive = !isActive">
		<router-link to="/parameters" class="text-white block uppercase p-4 text-right">Parâmetros</router-link>
		<router-link to="/syncData" class="text-white block uppercase p-4 text-right">Sincronizar Dados</router-link>
		<router-link to="/championshipSelect" class="text-white block uppercase p-4 text-right">Selecionar
			Campeonato</router-link>
		<router-link to="/about" class="text-white block uppercase p-4 text-right">Sobre</router-link>
	</ul>
</template>

<script>

import { Bars3Icon } from '@heroicons/vue/24/outline'

export default {
	data() {
		return {
			isActive: false,
		};
	},

	toggle() {
		this.isActive = !this.enable;
	},

	components: { Bars3Icon }
}
</script>

<style lang="postcss">
.burger {
	&.isActive {
		@apply ring-2;
	}
}

.menu {
	@apply opacity-0 invisible translate-x-full;

	&.isActive {
		@apply translate-x-0 opacity-100 visible;
	}
}

.router-link-active {
	@apply underline;
}
</style>