<template>
  <div v-if="isCancelingItem == true" class="weighingItem p-3 gap-4 flex flex-col break-all whitespace-nowrap">
    <p class="dark:text-white">Cancelar pesagem {{ itemToDelete }}</p>
    <div>
      <label class="block mb-2 dark:text-white">Motivo:</label>
      <select class="form-control" v-model="selectedReason">
        <option :value="0">Selecione motivo do cancelamento</option>
        <option class="bg-slate-700" :value="reason.descricao" v-for="(reason, index) in cancelReasons" :key="index">
          {{ reason.descricao }}
        </option>
      </select>
    </div>
    <button class="p-3 m-3 bg-app-400 dark:bg-app-400/40 text-slate-200 text-center rounded-md uppercase transition-all"
      @click="cancelWeighing(item.comprovante)">Cancelar pesagem</button>
    <button class="p-3 m-3 bg-app-400 dark:bg-app-400/40 text-slate-200 text-center rounded-md uppercase transition-all"
      @click="isCancelingItem = false">Voltar</button>
  </div>
  <div v-else-if="isRecoveringItem == true" class="weighingItem p-3 gap-4 flex flex-col break-all whitespace-nowrap">
    <p class="dark:text-white">Recuperar pesagem {{ itemToRecover }}</p>
    <button class="p-3 m-3 bg-app-400 dark:bg-app-400/40 text-slate-200 text-center rounded-md uppercase transition-all"
      @click="recoverWeighing(item.comprovante)">Recuperar pesagem</button>
    <button class="p-3 m-3 bg-app-400 dark:bg-app-400/40 text-slate-200 text-center rounded-md uppercase transition-all"
      @click="isRecoveringItem = false">Voltar</button>
  </div>
  <div v-else class="weighingItem p-3 gap-4 flex flex-col break-all whitespace-nowrap">
    <div class="flex flex-col text-center">
      <strong class="text-[10px]">{{ item.comprovante }}</strong>
      <div v-if="item.excluido == 'SIM'" class="text-center text-app-400 dark:text-white">*** PESAGEM CANCELADA
        ***<br /><span class="text-sm">( {{
          item.obs }} )</span></div>
    </div>

    <div class="flex flex-col flex-wrap text-xs gap-y-4">
      <div class="flex justify-between items-center gap-x-4">
        <span class="w-1/2">Competidor:</span>
        <span class="w-1/2 text-right">{{ item.competidor_nome }}</span>
      </div>

      <div class="flex justify-between items-center gap-x-4">
        <span class="w-1/2">Data/Hora:</span>
        <span class="w-1/2 text-right">{{ dateTime }}</span>
      </div>

      <div class="flex justify-between items-center gap-x-4">
        <span class="w-1/2">Serial:</span>
        <span class="w-1/2 text-right">{{ item.serial }}</span>
      </div>

      <div class="flex justify-between items-center gap-x-4">
        <span class="w-1/2">Espécie:</span>
        <span class="w-1/2 text-right">{{ item.especie_nome }}</span>
      </div>

      <div class="flex justify-between items-center gap-x-4">
        <span class="w-1/2">Isca:</span>
        <span class="w-1/2 text-right">{{ item.isca_nome }}</span>
      </div>

      <div class="flex justify-between items-center gap-x-4">
        <span class="w-1/2">Peso:</span>
        <span class="w-1/2 text-right">{{ formatedWeight }} kg</span>
      </div>

      <div class="flex justify-between items-center gap-x-4">
        <span class="w-1/2">Sincronizada:</span>
        <span v-if="item.sincronizada == 1" class="w-1/2 text-right">SIM</span>
        <span v-else class="w-1/2 text-right text-app-400 dark:text-white">NÃO</span>
      </div>

      <div class="flex justify-between items-center gap-x-4 w-full">
        <button class="p-3 bg-app-400 text-slate-200 text-center rounded-md uppercase transition-all"
          @click="$root.printReceipt(item.comprovante)">Imprimir</button>
        <button v-if="item.excluido == 'SIM'"
          class="p-3 bg-red-500 dark:bg-red-500/60 text-slate-200 text-center rounded-md uppercase transition-all"
          @click="showRecoverDialog(item.comprovante)">Recuperar
          </button>
        <button v-else
          class="p-3 bg-red-500 dark:bg-red-500/60 text-slate-200 text-center rounded-md uppercase transition-all"
          @click="showRemoveDialog(item.comprovante)">Cancelar
          </button>
      </div>

      <hr>

    </div>
  </div>
</template>

<script>
export default {
  name: "Weighing",
  methods: {
    //cancela pesagem
    showRemoveDialog(comprovante) {
      this.itemToDelete = comprovante;
      this.isCancelingItem = true;
    },
    async cancelWeighing(comprovante) {
      if (this.selectedReason == 0) {
        alert("Selecione o motivo da exclusão");
        return false;
      }
      //if(confirm("Deseja cancelar essa pesagem?")) {
      let checkPassword = await this.$root.askPassword();
      if (checkPassword == true) {
        //busca pesagem pelo comprovante (uuid)
        let store = this.$root.db
          .transaction(["pesagens"], "readonly")
          .objectStore("pesagens");
        const index = store.index("comprovante");
        index.get(comprovante).onsuccess = async (event) => {
          const weight = event.target.result;
          weight.excluido = "SIM";
          weight.obs = this.selectedReason;
          let store = this.$root.db
            .transaction(["pesagens"], "readwrite")
            .objectStore("pesagens");
          store.put(weight).onsuccess = (event) => {
            console.log("cancelou pesagem");
            this.item.excluido = "SIM";
            this.item.obs = this.selectedReason;
            this.itemToDelete = null;
            this.isCancelingItem = false;
            //envia atualização da pesagem pro servidor
            this.$root.sendWeighing(comprovante);
          };
        };
      }
    },
    showRecoverDialog(comprovante) {
      this.itemToRecover = comprovante;
      this.isRecoveringItem = true;
    },
    async recoverWeighing(comprovante) {
      let checkPassword = await this.$root.askPassword();
      if (checkPassword == true) {
        //busca pesagem pelo comprovante (uuid)
        let store = this.$root.db
          .transaction(["pesagens"], "readonly")
          .objectStore("pesagens");
        const index = store.index("comprovante");
        index.get(comprovante).onsuccess = async (event) => {
          const weight = event.target.result;
          weight.excluido = "NÃO";
          weight.obs = '';
          let store = this.$root.db
            .transaction(["pesagens"], "readwrite")
            .objectStore("pesagens");
          store.put(weight).onsuccess = (event) => {
            console.log("resgatou pesagem");
            this.item.excluido = "NÃO";
            this.item.obs = '';
            this.itemToRecover = null;
            this.isRecoveringItem = false;
            //envia atualização da pesagem pro servidor
            this.$root.sendWeighing(comprovante);
          };
        };
      }
    },
  },
  async mounted() {
    let dt = this.item.data_hora.split(" ")[0];
    this.dateTime = dt.split("-")[2] + "/" + dt.split("-")[1] + "/" + dt.split("-")[0] + " " + this.item.data_hora.split(" ")[1];
    this.formatedWeight = String(parseFloat(this.item.peso).toFixed(3)).replace('.', ',');
    //busca motivos de exclusão
    this.cancelReasons = [];
    let store = this.$root.db
      .transaction(["motivos_exclusao"], "readonly")
      .objectStore("motivos_exclusao");
    store.openCursor().addEventListener("success", (e) => {
      let cursor = e.target.result;
      if (cursor) {
        let item = {
          id: cursor.key,
          descricao: cursor.value.descricao,
        };
        this.cancelReasons.push(item);
        cursor.continue();
      }
    });
  },
  data: () => ({
    dateTime: "0000-00-00 00:00:00",
    formatedWeight: null,
    //species: { descricao: "" },
    //bait: { descricao: "" },
    cancelReasons: [],
    selectedReason: 0,
    isCancelingItem: false,
    itemToDelete: null,
    isRecoveringItem: false,
    itemToRecover: null,
  }),
  props: {
    item: Object,
  },
};
</script>