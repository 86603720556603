<template>
  <div>
    <header class="bg-app-400 p-4">
      <span class="uppercase text-white font-medium">{{ pageTitle }}</span>
    </header>

    <div class="flex flex-col flex-wrap p-4">
      <div v-if="!hasDecoded" class="mb-4">
        <div class="center stream">

          <qrcode-stream
            :constraints="selectedConstraints"
            :formats="['qr_code']"
            :torch="torch"
            @detect="onDetect"
            @error="onError"
          >
            <div class="frame"></div>
            <div class="torchBtn">
              <SunIcon class="h-6 w-6" :class="{'stroke-yellow':torch, 'stroke-gray':!torch}" @click="torch = !torch" />
            </div>
            <span class="qrcodeError">{{ qrcodeError }}</span>
          </qrcode-stream>
  
          <!--
          <qrcode-stream :camera="camera" @detect="onDetect" @init="onInit">
            <div class="frame"></div>
            <span class="qrcodeError">{{ qrcodeError }}</span>
          </qrcode-stream>
          -->

        </div>
      </div>
      <div v-else class="mb-4">
        <register-fish :team="team" :member="member"></register-fish>
      </div>
      <router-link to="/"
        class="block w-full text-white text-center p-4 rounded-md uppercase bg-app-400 dark:bg-app-400/40  transition-colors duration-500">
        Cancelar
      </router-link>
    </div>
  </div>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import RegisterFish from "@/components/RegisterFish.vue";
import { SunIcon } from '@heroicons/vue/24/outline'
export default {
  name: "QrCodeView",
  components: {
    QrcodeStream,
    RegisterFish,
    SunIcon,
  },
  methods: {

    onDetect(detectedCodes) {
      this.code = parseInt(detectedCodes[0].rawValue);
      console.log(this.code);
      if (isNaN(this.code)) {
        //qrcode não é um número
        this.qrcodeError = "QRcode inválido";
        setTimeout(() => {
          this.qrcodeError = "";
        }, "2000")
      } else {
        //busca equipe e competidor a partir do código
        let store = this.$root.db
          .transaction(["comp_equipes"], "readonly")
          .objectStore("comp_equipes");
        const index = store.index("pulseira_id");
        index.get(this.code).onsuccess = (event) => {
          console.log(event)
          if (event.target.result != undefined) {
            document.getElementById('okSound').play();
            this.team = event.target.result.equipe_id;
            this.member = event.target.result.competidor_id;
            this.hasDecoded = true;
            this.pageTitle = "Registrar Peixe";
          } else {
            document.getElementById('errorSound').play();
            this.qrcodeError = this.code + " não identificado";
            setTimeout(() => {
              this.qrcodeError = "";
            }, "2000")
          }
        };
      }      
    },

    onError(error) {
      if (error.name === "NotAllowedError") {
          this.error = "ERRO: permita que o app acesse a câmera";
        } else if (error.name === "NotFoundError") {
          this.error = "ERRO: câmera não encontrada";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERRO: precisa estar rodando em https ou localhost";
        } else if (error.name === "NotReadableError") {
          this.error = "ERRO: a câmera parece estar em uso";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERRO: a câmera não é compatível";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERRO: Stream API não localizada";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERRO: precisa estar rodando em https ou localhost";
        } else {
          this.error = `ERRO: Camera error (${error.name})`;
        }
    },





    /*
    async onDetect(codes) {
      //qrcode foi decodificado
      console.log(codes);
      return false;
      this.code = parseInt(codes[0].rawValue);
      if (isNaN(this.code)) {
        //qrcode não é um número
        //this.camera = "off";
        this.qrcodeError = "QRcode inválido";
        setTimeout(() => {
          this.qrcodeError = "";
          //this.camera = "auto";
        }, "2000")
      } else {
        //busca equipe e competidor a partir do código
        let store = this.$root.db
          .transaction(["comp_equipes"], "readonly")
          .objectStore("comp_equipes");
        const index = store.index("pulseira_id");
        index.get(this.code).onsuccess = (event) => {
          if (event.target.result != undefined) {
            document.getElementById('okSound').play();
            this.team = event.target.result.equipe_id;
            this.member = event.target.result.competidor_id;
            this.hasDecoded = true;
            this.pageTitle = "Registrar Peixe";
          } else {
            //this.camera = "off";
            document.getElementById('errorSound').play();
            this.qrcodeError = this.code + " não identificado";
            setTimeout(() => {
              this.qrcodeError = "";
              //this.camera = "auto";
            }, "2000")
          }
        };
      }
    },
    async onInit(promise) {
      try {
        await promise;
        console.log("init qrcode");
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },*/
  },
  mounted() {
    if (
       !this.$root.appStatus.db ||
       !this.$root.appStatus.parameters ||
       !this.$root.appStatus.data ||
       !this.$root.appStatus.championship
    ) {
      this.$router.push('/');
    }
  },
  data: () => ({
    pageTitle: "Ler QR Code",
    camera: "auto",
    code: null,
    hasDecoded: false,
    team: 0,
    member: 0,
    qrcodeError: "",
    selectedConstraints: { facingMode: 'environment' },
    torch: false,
  }),
};
</script>
<style scoped>

.stream {
  max-height: 500px;
  max-width: 500px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frame {
  /*background-color: rgba(255,255,255,0.5);*/
  border-style: solid;
  border-width: 2px;
  border-color: red;
  color: red;
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
  text-align: center;
}

.torchBtn {
  background-color:rgba(0,0,0,100);
  border-radius:4px;
  position: absolute;
  display: block;
  width:24px;
  height:24px;
  top: 2px;
  right: 2px;
  margin: auto;
  text-align: center;
  z-index:1000;
}

.qrcodeError {
  background-color: rgba(255,255,255,0.5);  
  color: black;
  font-size:20px;
  text-transform: uppercase;
  width: 100%;
  text-align:center;
  position: absolute;
  bottom: 0;
  margin: auto;
}
</style>
