<template>
  <div class="
                                                                                font-azoSans scroll-smooth min-h-screen transition duration-500
                                                                                bg-neutral-100 text-app-600
                                                                                dark:text-app-200 dark:bg-slate-900">
    <div class="overflow-x-hidden overflow-y-auto relative pt-20">
      <AppHeader />
      <slot />
    </div>

    <!-- conteúdo da página -->
    <router-view />

    <!-- modal senha -->
    <div class="modal" v-if="askingPassword == true">
      <div class="modal-content">
        <div class="flex flex-col flex-wrap gap-4 p-3">
          <input id="password" v-model="typedPassword" class="form-control" type="password" placeholder="SENHA">
          <button id="passwordButton"
            class="block w-full text-white text-center p-4 rounded-md uppercase bg-app-400 dark:bg-app-400/40 transition-colors duration-500">ok</button>
          <button id="closePasswordButton"
            class="block w-full text-white text-center p-4 rounded-md uppercase bg-app-400 dark:bg-app-400/40 transition-colors duration-500">cancelar</button>
          <p v-if="wrongPassword" class="alert-text text-center">Senha incorreta</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { nextTick } from 'vue';
import AppHeader from './components/AppHeader.vue';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import { useFavicon } from '@vueuse/core';
import { PosPrinterJob, getCurrentDriver, getCurrentTransport } from './assets/js/rawbt.js';
import iconv from './assets/js/iconv-lite.bundle.js';

// Setup for favicon
const icon = useFavicon()
icon.value = 'logo.png';

/* eslint-disable */
export default {
  name: "AppColetor",
  favicon: icon.value,
  methods: {
    //setup inicial
    async appSetup() {
      console.log("*** appSetup ***");
      //abre BD
      this.db = await this.getDB();
      if (typeof this.db != "object") {
        return false;
      }
      //verifica tabelas
      let dbOk = true;
      for (let tb in this.tableLoader) {
        if (!this.db.objectStoreNames.contains(this.tableLoader[tb].table)) {
          dbOk = false;
          break;
        }
      }
      if (!this.db.objectStoreNames.contains("parameters") || !this.db.objectStoreNames.contains("pesagens")) {
        dbOk = false;
      }
      //tabelas não estão ok, força criação de nova base
      if (!dbOk) {
        this.db = await this.getDB(true);
        this.appStatus.db = true;
      }
      //busca endereço do servidor e serial do coletor na BD
      this.deviceSerial = await this.getParameter("deviceSerial");
      this.serverAddress = await this.getParameter("serverAddress");
      if (this.deviceSerial !== false && this.deviceSerial.length > 0 && this.serverAddress !== false && this.serverAddress.length > 0) {
        if (this.serverAddress.slice(-1) != "/") this.serverAddress += "/";
        this.appStatus.parameters = true;
      }
      //busca campeonato e etapa atuais, caso já tenham sido definidos
      this.currentChampionship = await this.getParameter("currentChampionship");
      this.currentStage = await this.getParameter("currentStage");
      if (this.currentChampionship !== false && this.currentStage !== false) {
        //busca dados do campeonato e da etapa
        this.currentChampionshipData = await this.getChampionship(this.currentChampionship);
        this.currentStageData = await this.getStage(this.currentStage);
        if (this.currentChampionshipData !== false && this.currentStageData !== false) {
          this.appStatus.data = true;
          //verifica se etapa selecionada é hoje e tem fiscal associado ao coletor
          let dt = new Date();
          let today = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
          if (this.currentStageData.data == today && !isNaN(this.currentStageData.fiscal_id)) {
            this.appStatus.championship = true;
          }
        }
      }
      console.log("finalizou appSetup");
    },

    //conecta / cria base de dados do app
    async getDB(rebuild) {
      console.log("*** getDB ***");
      return new Promise((resolve, reject) => {
        //navegador não suporta indexedDB
        if (!("indexedDB" in window)) {
          console.error("Este navegador não suporta IndexedDB");
          reject("Error");
        }
        //se rebuild == true vamos dar um drop e recriar
        if (rebuild === true) {
          window.indexedDB.deleteDatabase("coletor");
        }
        //abrimos a base
        const openRequest = window.indexedDB.open("coletor", 1);
        openRequest.onerror = () => {
          console.error("Erro ao abrir DB");
          reject("Error");
        };
        openRequest.onsuccess = (e) => {
          //atualiza status do app
          this.appStatus.db = true;
          console.log("DB aberta com sucesso");
          resolve(e.target.result);
        };
        //cria base
        openRequest.onupgradeneeded = (e) => {
          console.log("Criando DB");
          let db = e.target.result;
          //tabela "parameters"
          let store = db.createObjectStore("parameters");
          store.createIndex("value", "value", { unique: false });
          //tabela "pesagens"
          store = db.createObjectStore("pesagens", {
            keyPath: "id",
            autoIncrement: true,
          });
          store.createIndex("competidor_id", "competidor_id", { unique: false });
          store.createIndex("equipe_id", "equipe_id", { unique: false });
          store.createIndex("comprovante", "comprovante", { unique: true });
          store.createIndex("sincronizada", "sincronizada", { unique: false });
          //cria tabelas para dados vindos do servidor
          for (let tab in this.tableLoader) {
            console.log(this.tableLoader[tab]);
            store = db.createObjectStore(this.tableLoader[tab].table);
            //seta campos índice
            if (this.tableLoader[tab].keys.length) {
              for (let key in this.tableLoader[tab].keys) {
                store.createIndex(
                  this.tableLoader[tab].keys[key],
                  this.tableLoader[tab].keys[key],
                  {
                    unique: false,
                  }
                );
              }
            }
          }
        };
      }).catch(function () {
        alert("Erro abrindo IndexedDB");
      });
    },

    //busca parâmetro na BD
    async getParameter(key) {
      console.log("*** getParameter " + key + " ***");
      return new Promise((resolve, reject) => {
        let store = this.db
          .transaction("parameters", "readonly")
          .objectStore("parameters");
        let request = store.get(key);
        request.onsuccess = (e) => {
          if (e.target.result != undefined) {
            resolve(e.target.result.value);
          } else {
            resolve(false);
          }
        };
        request.onerror = (e) => {
          console.error(e);
          reject("Error");
        };
      });
    },

    //busca dados do campeonato
    async getChampionship(id) {
      console.log("*** getChampionship " + id + " ***");
      return new Promise((resolve, reject) => {
        let store = this.db
          .transaction("campeonatos", "readonly")
          .objectStore("campeonatos");
        let request = store.get(id);
        request.onsuccess = (e) => {
          if (e.target.result != undefined) {
            resolve(e.target.result);
          } else {
            resolve(false);
          }
        };
        request.onerror = (e) => {
          console.error(e);
          reject("Error");
        };
      });
    },

    //busca dados da etapa
    async getStage(id) {
      console.log("*** getStage " + id + " ***");
      return new Promise((resolve, reject) => {
        let store = this.db
          .transaction("etapas", "readonly")
          .objectStore("etapas");
        let request = store.get(id);
        request.onsuccess = async (e) => {
          if (e.target.result != undefined) {
            //atualiza id global do fiscal antes de retornar
            this.supervisor = e.target.result.fiscal_id;
            //busca nome do fiscal pra completar no resultado
            let supv = await this.getItem("fiscais", this.supervisor);
            e.target.result.fiscal_nome = supv.nome;
            resolve(e.target.result);
          } else {
            resolve(false);
          }
        };
        request.onerror = (e) => {
          console.error(e);
          reject("Error");
        };
      });
    },

    //busca item de tabela
    async getItem(table, id) {
      id = parseInt(id);
      console.log("*** getItem " + table + " " + id + " ***");
      return new Promise((resolve, reject) => {
        let store = this.db
          .transaction(table, "readonly")
          .objectStore(table);
        let request = store.get(id);
        request.onsuccess = (e) => {
          if (e.target.result != undefined) {
            resolve(e.target.result);
          } else {
            resolve(false);
          }
        };
        request.onerror = (e) => {
          console.error(e);
          reject("Error");
        };
      });
    },

    //busca item de tabela
    async getItemByKey(table, field, value) {
      console.log("*** getItemByField " + table + " " + field + " " + value + " ***");
      return new Promise((resolve, reject) => {
        let store = this.db
          .transaction(table, "readonly")
          .objectStore(table);
        console.log(table,field,value);
        const index = store.index(field);
        index.get(value).onsuccess = (event) => {
          resolve(event.target.result);
        };
      });
    },

    //busca lista de equipes
    async getAllTeams() {
      console.log("*** getAllTeams ***");
      return new Promise((resolve, reject) => {
        let equipes = {};
        let store = this.$root.db
          .transaction(["equipes"], "readonly")
          .objectStore("equipes");
        store.openCursor().addEventListener("success", async (e) => {
          let cursor = e.target.result;
          if (cursor) {
            equipes[parseInt(cursor.value.id)] = cursor.value;
            cursor.continue();
          } else {
            resolve(equipes);
          }
        });
      });
    },

    //busca lista de competidores
    async getAllMembers() {
      console.log("*** getAllMembers ***");
      return new Promise((resolve, reject) => {
        let competidores = {};
        let store = this.$root.db
          .transaction(["competidores"], "readonly")
          .objectStore("competidores");
        store.openCursor().addEventListener("success", async (e) => {
          let cursor = e.target.result;
          if (cursor) {
            competidores[parseInt(cursor.value.id)] = cursor.value;
            cursor.continue();
          } else {
            resolve(competidores);
          }
        });
      });
    },

    //busca lista de espécies
    async getAllSpecies() {
      console.log("*** getAllSpecies ***");
      return new Promise((resolve, reject) => {
        let especies = {};
        let store = this.$root.db
          .transaction(["especies"], "readonly")
          .objectStore("especies");
        store.openCursor().addEventListener("success", async (e) => {
          let cursor = e.target.result;
          if (cursor) {
            especies[parseInt(cursor.value.id)] = cursor.value;
            cursor.continue();
          } else {
            resolve(especies);
          }
        });
      });
    },

    //busca lista de iscas
    async getAllBaits() {
      console.log("*** getAllBaits ***");
      return new Promise((resolve, reject) => {
        let iscas = {};
        let store = this.$root.db
          .transaction(["iscas"], "readonly")
          .objectStore("iscas");
        store.openCursor().addEventListener("success", async (e) => {
          let cursor = e.target.result;
          if (cursor) {
            iscas[parseInt(cursor.value.id)] = cursor.value;
            cursor.continue();
          } else {
            resolve(iscas);
          }
        });
      });
    },


    async printReceipt(comprovante) {
      console.log("*** printReceipt ***");
      //busca pesagem pelo comprovante (uuid)
      let store = this.db
        .transaction(["pesagens"], "readonly")
        .objectStore("pesagens");
      const index = store.index("comprovante");
      index.get(comprovante).onsuccess = async (event) => {
        const weight = event.target.result;
        let dt = weight.data_hora.split(" ")[0];
        let dateTime = dt.split("-")[2] + "/" + dt.split("-")[1] + "/" + dt.split("-")[0] + " " + weight.data_hora.split(" ")[1];
        let team = await this.getItem("equipes", weight.equipe_id);
        let member = await this.getItem("competidores", weight.competidor_id);
        let species = await this.getItem("especies", weight.especie_id);
        let bait = await this.getItem("iscas", weight.isca_id);
        let supervisor = await this.getItem("fiscais", this.supervisor);
        let logo = this.currentChampionshipData.imagem_pb;
        let championshipDescription = this.currentStageData.descricao;

        //imprime usando lib do rawbt
        let c = new PosPrinterJob(getCurrentDriver(), getCurrentTransport());
        c.initialize();
        c.feed(1);
        //etapa
        c.printText(championshipDescription, c.ALIGNMENT_CENTER, c.FONT_SIZE_MEDIUM2);
        c.feed(1);
        //se pesagem está excluída
        if (weight.excluido == "SIM") {
          c.printText(`*** PESAGEM CANCELADA ***`, c.ALIGNMENT_CENTER, c.FONT_SIZE_SMALL);
          c.feed(1);
          c.printText(weight.obs, c.ALIGNMENT_CENTER, c.FONT_SIZE_SMALL);
        }
        //peso
        c.printText('PESO:', c.ALIGNMENT_CENTER, c.FONT_SIZE_MEDIUM1);
        c.printText(`${String(parseFloat(weight.peso)).replace('.', ',')} kg`, c.ALIGNMENT_CENTER, c.FONT_SIZE_BIG);
        c.feed(1);
        //informações
        c.printText(`Equipe:\n${team.descricao.trim()}`, c.ALIGNMENT_LEFT, c.FONT_SIZE_SMALL);
        c.feed(1);
        c.printText(`Competidor:\n${member.nome.trim()}`, c.ALIGNMENT_LEFT, c.FONT_SIZE_SMALL);
        c.feed(1);
        c.printText(`Fiscal:\n${supervisor.nome.trim()}`, c.ALIGNMENT_LEFT, c.FONT_SIZE_SMALL);
        c.feed(2);
        c.printText(`Espécie: ${species.descricao.trim()}`, c.ALIGNMENT_LEFT, c.FONT_SIZE_SMALL);
        c.feed(1);
        c.printText(`Isca: ${bait.descricao.trim()}`, c.ALIGNMENT_LEFT, c.FONT_SIZE_SMALL);
        c.feed(1);
        c.printText(`Data/Hora: ${dateTime}`, c.ALIGNMENT_LEFT, c.FONT_SIZE_SMALL);
        c.feed(1);
        c.printText(`Comprovante: ${weight.comprovante}`, c.ALIGNMENT_CENTER, c.FONT_SIZE_SMALL);
        c.feed(2);
        c.execute();
      };
    },



    //imprime comprovante de pesagem - versão antiga, imprime via html e exige confirmação antes da impressão
    async printReceipt_old(comprovante) {
      //busca pesagem pelo comprovante (uuid)
      let store = this.db
        .transaction(["pesagens"], "readonly")
        .objectStore("pesagens");
      const index = store.index("comprovante");
      index.get(comprovante).onsuccess = async (event) => {
        const weight = event.target.result;
        let dt = weight.data_hora.split(" ")[0];
        let dateTime = dt.split("-")[2] + "/" + dt.split("-")[1] + "/" + dt.split("-")[0] + " " + weight.data_hora.split(" ")[1];
        let team = await this.getItem("equipes", weight.equipe_id);
        let member = await this.getItem("competidores", weight.competidor_id);
        let species = await this.getItem("especies", weight.especie_id);
        let bait = await this.getItem("iscas", weight.isca_id);
        let supervisor = await this.getItem("fiscais", this.supervisor);
        let logo = this.currentChampionshipData.imagem_pb;
        let championshipDescription = this.currentStageData.descricao;
        let weightStatusTxt = "";
        if (weight.excluido == "SIM") {
          weightStatusTxt = `<br /><br />*** PESAGEM CANCELADA ***<br />Motivo: ${weight.obs}<br /><br />`;
        }
        //cria iframe para impressão
        let printFrame = document.createElement("iframe");
        printFrame.style.display = "none";
        printFrame.onload = function () {
          this.contentWindow.__container__ = this;
          let iframeDocument = this.contentDocument || this.contentWindow.document;
          var styles = `
            .printedReceipt { max-width: 320px; font-family: 'Helvetica', 'Arial', sans-serif; }
            .w-1/2 { width: 50%; }
            .py-2 { padding-top: 0.5rem; padding-bottom: 0.5rem; }
            .p-3 { padding: 0.75rem; }
            .flex { display: flex; }
            .flex-col { flex-direction: column; }
            .flex-wrap { flex-wrap: wrap; }
            .items-center { align-items: center; }
            .justify-between { justify-content: space-between; }
            .gap-4 { gap: 1rem; }
            .gap-y-2 { row-gap: 0.5rem; }
            .gap-x-4 { column-gap: 1rem; margin-bottom: 0.25rem; }
            .mx-auto { margin-left: auto; margin-right: auto; }
            .break-all { word-break: break-all; }
            .block { display: block; }
            .text-8px { font-size: 8px; }
            .text-10px { font-size: 10px; }
            .text-xs { font-size: 0.75rem; line-height: 1rem; }
            .text-sm { font-size: 0.875rem; line-height: 1.25rem; }
            .text-center { text-align: center; }
            .object-contain { object-fit: contain; }
            hr { width: 100%; border-top: 1px dotted; margin: 0; height: 1px; }
            .logo { max-width: 120px; }
          `;
          let styleSheet = document.createElement("style")
          styleSheet.innerText = styles
          iframeDocument.head.appendChild(styleSheet)
          iframeDocument.body.innerHTML = `
              <div class="printedReceipt mx-auto">
                <div class="p-3 gap-4 flex flex-col break-all mx-auto whitespace-nowrap">
                  <div class="flex flex-col gap-y-2">
                    <img class="logo max-w-[120px] mx-auto object-contain" src="data:image/png;base64,${logo}">
                    <span class="text-center text-10px block">${championshipDescription}</span>
                    <span class="text-center text-sm block">${weightStatusTxt}</span>
                  </div>

                  <hr>
                  <div class="flex flex-col text-center">
                    <span class="block text-xs">Comprovante</span>
                    <strong class="text-8px">${weight.comprovante}</strong>
                  </div>
                  <hr>

                  <div class="flex flex-col flex-wrap text-10px gap-y-4">
                    <div class="flex justify-between items-center gap-x-4">
                      <span class="w-1/2">Data/Hora:</span>
                      <span class="w-1/2">${dateTime}</span>
                    </div>

                    <div class="flex justify-between items-center gap-x-4">
                      <span class="w-1/2">Serial:</span>
                      <span class="w-1/2">${weight.serial}</span>
                    </div>

                    <div class="flex justify-between items-center gap-x-4">
                      <span class="w-1/2">Fiscal:</span>
                      <span class="w-1/2">${supervisor.nome}</span>
                    </div>

                    <div class="flex justify-between items-center gap-x-4">
                      <span class="w-1/2">Equipe:</span>
                      <span class="w-1/2">${team.descricao}</span>
                    </div>

                    <div class="flex justify-between items-center gap-x-4">
                      <span class="w-1/2">Competidor:</span>
                      <span class="w-1/2">${member.nome}</span>
                    </div>

                    <div class="flex justify-between items-center gap-x-4">
                      <span class="w-1/2">Espécie:</span>
                      <span class="w-1/2">${species.descricao}</span>
                    </div>

                    <div class="flex justify-between items-center gap-x-4">
                      <span class="w-1/2">Isca:</span>
                      <span class="w-1/2">${bait.descricao}</span>
                    </div>

                    <div class="flex justify-between items-center gap-x-4">
                      <span class="w-1/2">Peso:</span>
                      <span class="w-1/2">${String(parseFloat(weight.peso)).replace('.', ',')} kg</span>
                    </div>
                  </div>
                </div>
              </div>
          `;
          this.contentWindow.onbeforeunload = this.closePrint;
          this.contentWindow.onafterprint = this.closePrint;
          this.contentWindow.focus(); //IE
          this.contentWindow.print();
        }
        document.body.appendChild(printFrame);
      };
    },
    closePrint() {
      document.body.removeChild(this.__container__);
    },

    //envia pesagem para o servidor
    sendWeighing(comprovante) {
      console.log("*** sendWeighing ***");
      return new Promise(async (resolve, reject) => {
        //busca pesagem pelo comprovante (uuid)
        let store = this.db
          .transaction(["pesagens"], "readonly")
          .objectStore("pesagens");
        const index = store.index("comprovante");
        let getWeighing = index.get(comprovante);
        getWeighing.onsuccess = (event) => {
          const weight = event.target.result;
          const config = {
            method: "get",
            url: this.serverAddress + "grava_pesagem.php",
            params: {
              id_etapa: weight.etapa_id,
              id_cad_competidor: weight.competidor_id,
              id_cad_equipe: weight.equipe_id,
              id_cad_especie: weight.especie_id,
              data_hora: weight.data_hora,
              id_cad_modalidade: weight.modalidade_id,
              peso: parseFloat(weight.peso),
              excluido: weight.excluido,
              obs: weight.obs,
              comprovante: comprovante,
              nome_fiscal: weight.fiscal_nome,
              id_cad_isca: weight.isca_id,
              serial_instrumento: weight.serial,
              app_version: "2", //vamos diferenciar algumas respostas no back de acordo com a versão
            },
          }
          let requestError = false;
          this.$axios(config)
            .catch((error) => {
              if (error.response) {
                //servidor respondeu com status fora de 2xx
                console.error(error.response);
              } else if (error.request) {
                // servidor não respondeu
                console.error(error.request);
              } else {
                //Algum erro ao setar o request
                console.error(error.message);
              }
              requestError = true;
            })
            .then((response) => {
              if (!requestError) {
                if (response.data.sucesso) {
                  //atualiza pesagem local
                  weight.sincronizada = 1;
                  weight.excluido = response.data.excluido;
                  weight.obs = response.data.obs;
                  let store = this.db
                    .transaction(["pesagens"], "readwrite")
                    .objectStore("pesagens");
                  store.put(weight).onsuccess = (event) => {
                    console.log("atualizou pesagem");
                    resolve(true);
                  };
                  //se campo "liberar" vier com comprovante, precisamos restaurar pesagem cancelada por excesso de capturas
                  if(parseInt(response.data.liberar) != 0) {
                    this.restoreWeighing(response.data.liberar);
                  }

                } else {
                  resolve(false);
                }
              } else {
                resolve(false);
              }
            });

        };
      });

    },
    //"descancela" pesagem na base local
    restoreWeighing(comprovante) {
      console.log("*** restoreWeighing ***");
      return new Promise(async (resolve, reject) => {
        //busca pesagem pelo comprovante (uuid)
        let store = this.db
          .transaction(["pesagens"], "readonly")
          .objectStore("pesagens");
        const index = store.index("comprovante");
        let getWeighing = index.get(comprovante);
        getWeighing.onsuccess = (event) => {
          const weight = event.target.result;
          weight.excluido = 'NAO';
          weight.obs = '';
          let store = this.db
            .transaction(["pesagens"], "readwrite")
            .objectStore("pesagens");
          store.put(weight).onsuccess = (event) => {
            console.log("atualizou pesagem");
            resolve(true);
          };
        };
      });
    },
    //limpa tabela de pesagens
    clearData() {
      let store = this.$root.db
        .transaction(["pesagens"], "readwrite")
        .objectStore("pesagens");
      store.clear();
    },
    //pede senha e retorna true ou false
    async askPassword() {
      this.askingPassword = true;
      this.typedPassword = "";
      await nextTick();
      document.getElementById("password").focus();
      return new Promise((resolve, reject) => {
        document.getElementById("passwordButton").addEventListener('click', (e) => {
          if (this.typedPassword == "iddqd1") {
            this.wrongPassword = false;
            this.closePassword();
            resolve(true);
          } else {
            this.wrongPassword = true;
          }
        });
        document.getElementById("closePasswordButton").addEventListener('click', (e) => {
          this.closePassword();
          resolve(false);
        });
      });
    },
    //fecha modal da senha
    closePassword() {
      this.askingPassword = false;
    },

  },
  mounted() {
    document.title = "Coletor CBP";
    this.appSetup();
  },
  data: () => ({
    appStatus: {
      db: false,
      parameters: false,
      data: false,
      championship: false,
    },
    db: null,
    currentChampionship: false,
    currentChampionshipData: null,
    currentStage: false,
    currentStageData: null,
    deviceSerial: false,
    serverAddress: false,
    supervisor: false,
    askingPassword: false,
    typedPassword: "",
    wrongPassword: false,
    tableLoader: [
      {
        endpoint: "cad_especies",
        table: "especies",
        fields: {
          id: "id",
          descricao: "descricao",
          prioridade: "prioridade",
        },
        keys: [],
      },
      {
        endpoint: "cad_motivos_exclusao",
        table: "motivos_exclusao",
        fields: {
          id: "id",
          descricao: "descricao",
        },
        keys: [],
      },
      {
        endpoint: "cad_fiscais",
        table: "fiscais",
        fields: {
          id: "id",
          nome: "nome",
          cpf: "cpf",
        },
        keys: [],
      },
      {
        endpoint: "cad_unidades_medida",
        table: "unidades_medida",
        fields: {
          id: "id",
          descricao: "descricao",
          abreviacao: "abreviacao",
        },
        keys: [],
      },
      {
        endpoint: "cad_modalidades",
        table: "modalidades",
        fields: {
          id: "id",
          descricao: "descricao",
          prioridade: "prioridade",
        },
        keys: [],
      },
      {
        endpoint: "cad_iscas",
        table: "iscas",
        fields: {
          id: "id",
          descricao: "descricao",
          prioridade: "prioridade",
        },
        keys: [],
      },
      {
        endpoint: "cad_campeonatos",
        table: "campeonatos",
        fields: {
          id: "id",
          descricao: "descricao",
          descricao_app: "descricao_app",
          imagem: "imagem",
          imagem_pb: "imagem_pb",
        },
        keys: [],
      },
      {
        endpoint: "cad_etapas",
        table: "etapas",
        fields: {
          id: "id",
          id_cad_campeonato: "campeonato_id",
          descricao: "descricao",
          descricao_app: "descricao_app",
          data_etapa: "data",
          imagem: "imagem",
          imagem_pb: "imagem_pb",
          peso_minimo: "peso_minimo",
          id_cad_fiscal: "fiscal_id",
          id_cad_unidade_medida: "unidade_medida",
          id_cad_tipo_classificacao: "tipo_classificacao",
        },
        keys: ["campeonato_id", "fiscal_id"],
      },
      {
        endpoint: "cad_datas_etapas",
        table: "datas_etapas",
        fields: {
          id: "id",
          id_cad_etapa: "etapa_id",
          data_etapa: "data",
        },
        keys: ["etapa_id"],
      },
      {
        endpoint: "cad_equipes",
        table: "equipes",
        fields: {
          id: "id",
          descricao: "descricao",
          id_cad_equipe: "etapa_id", //nome do campo está errado no back (id_cad_equipe) deveria ser id_cad_etapa
        },
        keys: ["etapa_id"],
      },
      {
        endpoint: "cad_competidores",
        table: "competidores",
        fields: {
          id: "id",
          nome: "nome",
          tamanho_camiseta: "camiseta",
        },
        keys: [],
      },
      {
        endpoint: "composicao_peixes_alvo",
        table: "comp_peixes_alvo",
        fields: {
          id: "id",
          id_cad_especie: "especie_id",
          id_cad_etapa: "etapa_id",
        },
        keys: ["especie_id", "etapa_id"],
      },
      {
        endpoint: "composicao_etapas",
        table: "comp_etapas",
        fields: {
          id: "id",
          id_cad_etapa: "etapa_id",
          id_cad_equipe: "equipe_id",
        },
        keys: ["etapa_id", "equipe_id"],
      },
      {
        endpoint: "composicao_equipes",
        table: "comp_equipes",
        fields: {
          id: "id",
          id_competidor: "competidor_id",
          id_equipe: "equipe_id",
          id_pulseira: "pulseira_id",
        },
        keys: ["competidor_id", "equipe_id", "pulseira_id"],
      },
    ],
  }),
  components: {
    AppHeader,
    ExclamationCircleIcon
  },
};
</script>

<style>
@font-face {
  font-family: "Lexend";
  src: local("Lexend"), url("../src/assets/fonts/Lexend-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "azo_sansregular";
  src: url("../src/assets/fonts/azosans-regular-webfont.woff2") format("woff2"),
    url("../src/assets/fonts/azosans-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  /* font-family: "Lexend", Helvetica, Arial, sans-serif; */
  font-family: "azo_sansregular", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.alert-text {
  color: orange;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 30px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 6px;
  box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
  width: 80%;
  text-align: center;
}

.dark .modal-content {
  background-color: #0f172a;
  border-color: #444;
}
</style>
