<template>
  <div>
    <header class="bg-app-400 p-4">
      <span class="uppercase text-slate-200 font-medium">Sincronizar Dados</span>
    </header>
  </div>

  <div class="syncData flex flex-col flex-wrap p-4">

    <div class="mb-4">
      <button class="flex items-center justify-center w-full text-slate-200 text-center
                 p-4 rounded-md uppercase bg-app-400 dark:bg-app-400/40  transition-colors duration-500"
        @click="sendData()">
        <ArrowUpTrayIcon class="h-6 w-6 stroke-white mr-2" />
        <span>Enviar Dados</span>
      </button>
    </div>

    <div class="mb-4">
      <button class="flex items-center justify-center w-full text-slate-200 text-center
                   p-4 rounded-md uppercase bg-app-400 dark:bg-app-400/40  transition-colors duration-500"
        @click="loadData()">
        <ArrowDownTrayIcon class="h-6 w-6 stroke-white mr-2" />
        <span>Receber Dados</span>
      </button>
    </div>

    <div class="mb-4">
      <button class="flex items-center justify-center w-full text-slate-200 text-center
                     p-4 rounded-md uppercase bg-app-400 dark:bg-app-400/40  transition-colors duration-500"
        @click="clearData()">
        <TrashIcon class="h-6 w-6 stroke-white mr-2" />
        <span>Limpar base de dados</span>
      </button>
    </div>

    <ul class="bg-green-300 rounded-md pb-4" v-if="loadedDataDisplay.length > 0">
      <div class="p-4 mb-2 bg-green-400 flex items-center rounded-t-md">
        <CheckCircleIcon class="h-6 w-6 stroke-white mr-2" />
        <span class="text-white uppercase">Dados Atualizados</span>
      </div>

      <li class="pl-4 pr-4 text-app-600" v-for="(item, index) in loadedDataDisplay" :key="index">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>

import { CheckCircleIcon } from '@heroicons/vue/24/outline';
import { ArrowUpTrayIcon } from '@heroicons/vue/24/outline';
import { ArrowDownTrayIcon } from '@heroicons/vue/24/outline';
import { TrashIcon } from '@heroicons/vue/24/outline';

export default {
  name: "SyncDataView",
  methods: {
    //recebe dados do servitor
    async loadData() {
      console.log("*** loadData ***");
      if (
        this.$root.serverAddress.length == 0 ||
        this.$root.deviceSerial.length == 0
      ) {
        alert("Os parâmetros de acesso ao servidor não foram definidos");
        return false;
      }
      let endpoints = [];
      this.$root.tableLoader.forEach((ep) => {
        endpoints.push(ep.endpoint);
      });
      let requestError = false;
      //faz uma requisição para cada endpoint
      Promise.all(
        endpoints.map((endpoint) =>
          this.$axios.get(
            this.$root.serverAddress +
            endpoint +
            ".php?serialColetor=" +
            this.$root.deviceSerial
          )
        )
      )
        .catch((error) => {
          if (error.response) {
            //servidor respondeu com status fora de 2xx
            console.error(error.response);
          } else if (error.request) {
            // servidor não respondeu
            console.error(error.request);
          } else {
            //Algum erro ao setar o request
            console.error(error.message);
          }
          requestError = true;
        })
        .then((result) => {
          //cria array result com responses das requisições
          let objectStore;
          let itemCount = 0;
          let curTable;
          if (!requestError) {
            //todos os dados foram recebidos
            this.loadedDataDisplay = [];
            result = result.map((item) => item.data);
            for (let i in result) {
              let entryKey = Object.keys(result[i])[0];
              console.log(entryKey);
              let entry = Object.values(result[i])[0];
              //entry = [ {id:1, descricao:"PIRARARA", prioridade:1}, {...}, ...]
              this.loadedDataDisplay.push(entryKey + " (" + entry.length + ")");
              itemCount = 0;
              if (curTable != i) {
                //abre tabela para escrita
                curTable = i;
                if (this.$root.tableLoader[curTable] == undefined) continue;
                objectStore = this.$root.db
                  .transaction(
                    this.$root.tableLoader[curTable].table,
                    "readwrite"
                  )
                  .objectStore(this.$root.tableLoader[curTable].table);
                //zera tabela
                objectStore.clear();
              }
              for (let k in entry) {
                //insere itens na tabela
                let item = {};
                for (let l in entry[k]) {
                  let fieldName = this.$root.tableLoader[curTable].fields[l];
                  let value = entry[k][l];
                  //se campo é um id precisamos converter pra int (importação direto da base online trazia em string / local trazia int)
                  if (fieldName.includes("_id") || fieldName == "id") {
                    value = parseInt(value);
                  }
                  item[fieldName] = value;
                }
                //id do item no servidor é usado de chave primária na base local
                objectStore.put(item, parseInt(entry[k].id));
                itemCount++;
              }
              console.log(
                this.$root.tableLoader[curTable].table + ": " + itemCount
              );
            }
            //atualiza status do app (data)
            this.$root.appStatus.data = true;
            //como carregou os dados agora, precisa selecionar novamente o campeonato
            this.$root.appStatus.championship = false;
            this.$root.currentChampionshipData = null,
              this.$root.currentStageData = null,
              this.$root.currentChampionship = false;
            this.$root.currentStage = false;
            let store = this.$root.db
              .transaction("parameters", "readwrite")
              .objectStore("parameters");
            store.put({ value: this.$root.currentChampionship }, "currentChampionship");
            store.put({ value: this.$root.currentStage }, "currentStage");
          } else {
            alert("Erro ao acessar servidor");
            return false;
          }
        });
    },
    //envia pesagens ao servidor
    async sendData() {
      console.log("*** sendData ***");
      let uuids = [];
      let sentWeighings = 0;
      //busca pesagens
      let store = this.$root.db
        .transaction(["pesagens"], "readonly")
        .objectStore("pesagens");
      store.openCursor().addEventListener("success", async (e) => {
        let cursor = e.target.result;
        if (cursor) {
          //loop buscando pesagens
          uuids.push(cursor.value.comprovante);
          cursor.continue();
        } else {
          //envia cada pesagem
          if (uuids.length == 0) {
            alert("Não há pesagens para enviar");
            return false;
          }
          for (let uuid of uuids) {
            let sent = await this.$root.sendWeighing(uuid);
            if (sent == true) {
              sentWeighings++;
            }
            console.log("Enviou???", sent);
          }
          alert(sentWeighings + " de " + uuids.length + " pesagens enviadas com sucesso")
        }
      });
    },
    async clearData() {
      let checkPassword = await this.$root.askPassword();
      if (checkPassword == true) {
        this.$root.clearData();
      }
    },
  },
  mounted() {
    if (
      !this.$root.appStatus.db ||
      !this.$root.appStatus.parameters
    ) {
      this.$router.push('/');
    }
  },
  data: () => ({
    loadedDataDisplay: [],
  }),

  components: {
    CheckCircleIcon,
    ArrowUpTrayIcon,
    ArrowDownTrayIcon,
    TrashIcon
  }

};
</script>
