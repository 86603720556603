<template>
  <div>
    <header class="bg-app-400 dark:bg-app-400 p-4">
      <span class="uppercase text-white font-medium">Registrar pesagem</span>
    </header>
    <register-fish :team="0" :member="0"></register-fish>
  </div>
</template>

<script>
import RegisterFish from "@/components/RegisterFish.vue";
export default {
  name: "RegisterFishView",
  components: {
    RegisterFish,
  },
  methods: {
  },
  mounted() {
    if (
      !this.$root.appStatus.db ||
      !this.$root.appStatus.parameters ||
      !this.$root.appStatus.data ||
      !this.$root.appStatus.championship
    ) {
      this.$router.push('/');
    }
  },
  data: () => ({
    team: 0,
    member: 0,
  }),
};
</script>