<template>
	<button class="theme-mode p-3 h-12 w-12" type="button" @click="toggleDark()">
		<SunIcon class="h-6 w-6  stroke-white dark:hidden" />
		<MoonIcon class="h-6 w-6 stroke-white hidden dark:inline" />
	</button>
</template>

<script setup>
import { useDark, useToggle } from '@vueuse/core'

const isDark = useDark({
	onChanged(isDark) {
		if (isDark) {
			document.documentElement.classList.add('dark')
		} else {
			document.documentElement.classList.remove('dark')
		}
	},
})

const toggleDark = useToggle(isDark)
</script>

<script>
import { SunIcon, MoonIcon } from '@heroicons/vue/24/outline'

export default {
	components: { SunIcon, MoonIcon }
}
</script>
