<template>
  <div>
    <header class="bg-app-400 dark:bg-app-400 p-4">
      <span class="uppercase text-white font-medium">Lançamentos</span>
    </header>

    <div>
      <div v-if="Object.keys(weighingsByTime).length === 0">
        <span class="uppercase text-center dark:text-white font-medium">Não há pesagens cadastradas</span>
      </div>

      <div v-else>
        <div v-if="orderBy == 'time'">
          <weighing v-for="(item, index) in weighingsByTime" :key="index" :item="item"></weighing>
        </div>
        <div v-else>
          <div class="team" v-for="(team, index) in weighingsByTeam" :key="index">
            <button class="flex flex-row flex-wrap p-4 bg-slate-600 w-full justify-between">
              <span class="uppercase text-white font-medium">[{{ index + "] " + team.teamName + " (" + team.items.length
                + ")" }}</span>
            </button>
            <div class="team-weight">
              <weighing v-for="(item, index2) in team.items" :key="index2" :item="item"></weighing>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Weighing from "@/components/Weighing.vue";
export default {
  name: "WeighingsView",
  components: {
    Weighing,
  },
  methods: {
    async loadWeighings() {

      let teams = await this.$root.getAllTeams();
      let members = await this.$root.getAllMembers();
      let species = await this.$root.getAllSpecies();
      let baits = await this.$root.getAllBaits();

      //carrega pesagens da bd local
      console.log("*** loadWeighings ***");
      let wbtime = [];
      let wbteam = [];
      let store = this.$root.db
        .transaction(["pesagens"], "readonly")
        .objectStore("pesagens");
      store.openCursor().addEventListener("success", async (e) => {
        let cursor = e.target.result;
        if (cursor) {

          let w = cursor.value;
          w.competidor_nome = members[parseInt(w.competidor_id)].nome;
          w.equipe_nome = teams[parseInt(w.equipe_id)].descricao;
          w.especie_nome = species[parseInt(w.especie_id)].descricao;
          w.isca_nome = baits[parseInt(w.isca_id)].descricao;
          //pesagens por equipe
          if (wbteam[w.equipe_id] == undefined) {
            wbteam[w.equipe_id] = { teamName: teams[parseInt(w.equipe_id)].descricao, items: [] };
          }
          wbteam[w.equipe_id].items.unshift(w);
          //pesagens por tempo
          wbtime.unshift(w);
          cursor.continue();

        } else {

          this.weighingsByTime = wbtime;
          this.weighingsByTeam = wbteam;

         }
      });
    },
  },
  mounted() {
    if (
      this.$root.appStatus.db &&
      this.$root.appStatus.parameters &&
      this.$root.appStatus.data &&
      this.$root.appStatus.championship
    ) {
      this.loadWeighings();
    } else {
      this.$router.push('/');
    }
  },
  data: () => ({
    weighingsByTeam: {},
    weighingsByTime: [],
    uuids: [],
    orderBy: "time",
  }),
};
</script>