<template>
  <div>
    <div class="flex flex-col flex-wrap gap-3 p-3">
      <div class="flex justify-between gap-x-4">
        <div class="w-1/2">
          <label class="block">Equipe:</label>
          <select class="form-control" v-model="selectedTeam">
            <option class="dark:bg-slate-700" :value="item.id" v-for="(item, index) in teams" :key="index">
              {{ item.descricao }}
            </option>
          </select>
        </div>

        <div class="w-1/2">
          <label class="block">Competidor:</label>
          <select class="form-control" v-model="selectedMember">
            <option class="dark:bg-slate-700" :value="item.id" v-for="(item, index) in members" :key="index">
              {{ item.nome }}
            </option>
          </select>
        </div>
      </div>

      <div>
        <label class="block">Espécie:</label>
        <select class="form-control" v-model="selectedSpecies">
          <option class="dark:bg-slate-700" :value="item.id" v-for="(item, index) in species" :key="index">
            {{ item.descricao }}
          </option>
          <option class="dark:bg-slate-700" :value="item.id" v-for="(item, index) in nonPrioritySpecies" :key="index">
            {{ item.descricao }}
          </option>
        </select>
      </div>

      <div>
        <label class="block">Modalidade:</label>
        <select class="form-control" v-model="selectedModality">
          <option class="dark:bg-slate-700" :value="item.id" v-for="(item, index) in modalities" :key="index">
            {{ item.descricao }}
          </option>
          <option class="dark:bg-slate-700" :value="item.id" v-for="(item, index) in nonPriorityModalities" :key="index">
            {{ item.descricao }}
          </option>
        </select>
      </div>

      <div>
        <label class="block">Isca:</label>
        <select class="form-control" v-model="selectedBait">
          <option class="dark:bg-slate-700" :value="item.id" v-for="(item, index) in baits" :key="index">
            {{ item.descricao }}
          </option>
          <option class="dark:bg-slate-700" :value="item.id" v-for="(item, index) in nonPriorityBaits" :key="index">
            {{ item.descricao }}
          </option>
        </select>
      </div>

      <div>
        <label class="block">Peso (kg):</label>
        <input class="form-control" type="tel" placeholder="0,000" v-model="weight" v-on:keyup="formatWeight" />
      </div>

      <div class="flex justify-between gap-x-4">
        <button class="w-1/2 p-4 bg-app-400 dark:bg-app-400/40 text-white text-center rounded-md uppercase transition-all"
          @click="saveWeighing()">salvar</button>
        <router-link to="/"
          class="w-1/2 p-4 bg-red-500 dark:bg-red-500/60 text-slate-200 text-center rounded-md uppercase transition-all">
          Cancelar
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "RegisterFish",
  methods: {
    async saveWeighing() {
      console.log("*** saveWeighing ***");
      if (this.selectedTeam == 0) {
        alert("A equipe não foi selecionada");
        return false;
      }
      if (this.selectedMember == 0) {
        alert("O competidor não foi selecionado");
        return false;
      }
      if (this.selectedSpecies == 0) {
        alert("A espécie não foi selecionada");
        return false;
      }
      if (this.selectedModality == 0) {
        alert("A modalidade não foi selecionada");
        return false;
      }
      if (this.selectedBait == 0) {
        alert("A isca não foi selecionada");
        return false;
      }

      let excluido = "NAO";
      let obs = '';

      //não aceita registro se exemplar está abaixo do peso mínimo
      if (isNaN(this.weight) || this.weight == null || parseFloat(this.weight) < this.$root.currentStageData.peso_minimo) {
        alert("Exemplar abaixo do peso mínimo");
        return false;
      }

      //restrições abaixo são apenas para o campeonato OPEN
      /*
      //se peso está abaixo, salva como excluído
      if (isNaN(this.weight) || this.weight == null || parseFloat(this.weight) < this.$root.currentStageData.peso_minimo) {
        alert("Exemplar abaixo do peso mínimo");
        return false;
        //excluido = 'SIM';
        //obs = 'EXEMPLAR ABAIXO DO PESO MÍNIMO';
      }
      
      //se tipo de classificação da etapa ID_CAD_TIPO_CLASSIFICACAO = "2" (Considerar 3 maiores exemplares, exceto cancelados) precisamos limitar as pesagens a 6 no total
      if(this.$root.currentStageData.tipo_classificacao == "2") {
        let memberAmount = await this.loadFishByMember(this.selectedMember);
        if(memberAmount.valid >= 6) {
          excluido = 'SIM';
          obs = 'EXCEDEU LIMITE DE CAPTURAS';
        }
      }
      */

      //data atual
      let dt = new Date();
      let curDate = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
      let curTime = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000)).toISOString().split("T")[1].split(".")[0];
      let currentDateTime = `${curDate} ${curTime}`;
      //uuid do comprovante
      let uuid = uuidv4();
      //abre tabela de pesagens para escrita
      let transaction = this.$root.db.transaction("pesagens", "readwrite");
      transaction.oncomplete = () => {
        //ao finalizar imprime comprovante...
        this.$root.printReceipt(uuid);
        //envia pesagem para o servidor...
        this.$root.sendWeighing(uuid);
        //e redireciona pra home
        this.$router.push({ path: "/" });
      };
      let store = transaction.objectStore("pesagens");
      //salva nova pesagem
      let weighing = {
        etapa_id: this.$root.currentStage,
        competidor_id: this.selectedMember,
        equipe_id: this.selectedTeam,
        especie_id: this.selectedSpecies,
        data_hora: currentDateTime,
        modalidade_id: this.selectedModality,
        peso: parseFloat(this.weight),
        excluido: excluido,
        obs: obs,
        comprovante: uuid,
        serial: this.$root.deviceSerial,
        fiscal_nome: this.$root.currentStageData.fiscal_nome,
        isca_id: this.selectedBait,
        sincronizada: 0,
      };
      store.put(weighing);
    },
    async loadTeams() {
      //carrega equipes da etapa selecionada
      console.log("*** loadTeams ***");
      this.teams = [];
      this.members = [];
      let store = this.$root.db
        .transaction(["equipes"], "readonly")
        .objectStore("equipes");
      const index = store.index("etapa_id");
      index.getAll(this.$root.currentStage).onsuccess = (event) => {
        this.teams.push({ id: 0, descricao: "Selecione equipe" });
        for (let i in event.target.result) {
          this.teams.push(event.target.result[i]);
        }
        this.selectedTeam = this.team;
      };
    },
    async loadMembers() {
      //carrega competidores da equipe selecionada
      console.log("*** loadMembers ***");
      this.members = [];
      if (this.selectedTeam == 0) return false;
      //buscamos os ids dos competidores que pertencem à equipe
      let store = this.$root.db
        .transaction(["comp_equipes"], "readonly")
        .objectStore("comp_equipes");
      const index = store.index("equipe_id");
      index.getAll(this.selectedTeam).onsuccess = (event) => {
        this.members.push({ id: 0, nome: "Selecione competidor" });
        let store2 = this.$root.db
          .transaction("competidores", "readonly")
          .objectStore("competidores");
        for (let i in event.target.result) {
          //buscamos competidores a partir do id
          let requestMember = store2.get(event.target.result[i].competidor_id);
          requestMember.onsuccess = (e) => {
            if (e.target.result != undefined) {
              this.members.push(e.target.result);
            }
            this.selectedMember = this.member;
          };
        }
      };
    },
    async loadSpecies() {
      //carrega Espécies de peixes da etapa
      console.log("*** loadSpecies ***");
      this.species = [];
      this.species.push({ id: 0, descricao: "Selecione especie" });
      this.nonPrioritySpecies = [];
      //buscamos os ids das espécies presentes na etapa
      let store = this.$root.db
        .transaction(["comp_peixes_alvo"], "readonly")
        .objectStore("comp_peixes_alvo");
      const index = store.index("etapa_id");
      index.getAll(this.$root.currentStage).onsuccess = (event) => {
        let store2 = this.$root.db
          .transaction("especies", "readonly")
          .objectStore("especies");
        for (let i in event.target.result) {
          //buscamos espécies a partir do id
          let requestSpecies = store2.get(event.target.result[i].especie_id);
          requestSpecies.onsuccess = (e) => {
            if (e.target.result != undefined) {
              if(e.target.result.prioridade) {
                this.species.push(e.target.result);
              } else {
                this.nonPrioritySpecies.push(e.target.result);
              }
            } else {
              console.log('uuu')
            }
          };
        }
      };
    },
    async loadModalities() {
      //carrega modalidades
      console.log("*** loadModalities ***");
      this.modalities = [];
      this.nonPriorityModalities = [];
      let store = this.$root.db
        .transaction(["modalidades"], "readonly")
        .objectStore("modalidades");
      store.openCursor().addEventListener("success", (e) => {
        let cursor = e.target.result;
        if (cursor) {
          let item = {
            id: cursor.key,
            descricao: cursor.value.descricao,
          };
          if(cursor.value.prioridade) {
            this.modalities.push(item);
          } else {
            this.nonPriorityModalities.push(item);
          }
          cursor.continue();
        } else {
          this.modalities.sort((a, b) => a.descricao.localeCompare(b.descricao));
          this.modalities.unshift({ id: 0, descricao: "Selecione modalidade" });
          this.nonPriorityModalities.sort((a, b) => a.descricao.localeCompare(b.descricao));
        }
      });
    },
    async loadBaits() {
      //carrega iscas
      console.log("*** loadBaits ***");
      this.baits = [];
      this.nonPriorityBaits = [];
      let store = this.$root.db
        .transaction(["iscas"], "readonly")
        .objectStore("iscas");
      store.openCursor().addEventListener("success", (e) => {
        let cursor = e.target.result;
        if (cursor) {
          let item = {
            id: cursor.key,
            descricao: cursor.value.descricao,
          };
          if(cursor.value.prioridade) {
            this.baits.push(item);
          } else {
            this.nonPriorityBaits.push(item);
          }
          cursor.continue();
        } else {
          this.baits.sort((a, b) => a.descricao.localeCompare(b.descricao));
          this.baits.unshift({ id: 0, descricao: "Selecione isca" });
          this.nonPriorityBaits.sort((a, b) => a.descricao.localeCompare(b.descricao));
        }
      });
    },
    formatWeight() {
      let digits = String(this.weight).replace(/\D/g, '');
      this.weight = parseFloat(digits / 1000).toFixed(3);
    },
    async loadFishByMember(id) {
      let amount = 0;
      let kg = 0;
      let valid = 0;
      const validReasons = ['PESAGEM / MEDIDA INCORRETA', 'COMPETIDOR INCORRETO', 'ESPÉCIE INCORRETA', 'REGISTRO DUPLICADO'];
      return new Promise((resolve, reject) => {
        let store = this.$root.db
          .transaction(["pesagens"], "readonly")
          .objectStore("pesagens");
        const index = store.index("competidor_id");
        index.getAll(id).onsuccess = async (event) => {
          for (let i in event.target.result) {
            if(event.target.result[i].excluido == "NAO") {
              amount++;
              valid++;
              kg += event.target.result[i].peso;
            } else if(!validReasons.includes(event.target.result[i].obs)) {
              valid++;
            }
          }
          resolve({ amount: amount, kg: kg, valid: valid });
        };
      });
    },

  },
  mounted() {
    this.loadTeams();
    this.loadSpecies();
    this.loadModalities();
    this.loadBaits();
  },
  data: () => ({
    teams: [],
    selectedTeam: 0,
    members: [],
    selectedMember: 0,
    species: [],
    nonPrioritySpecies: [],
    selectedSpecies: 0,
    modalities: [],
    nonPriorityModalities: [],
    selectedModality: 0,
    baits: [],
    nonPriorityBaits: [],
    selectedBait: 0,
    weight: null,
  }),
  props: {
    team: Number,
    member: Number,
  },
  watch: {
    selectedTeam: "loadMembers",
  },
};
</script>
