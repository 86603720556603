import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ParametersView from "../views/ParametersView.vue";
import SyncDataView from "../views/SyncDataView.vue";
import ChampionshipSelectView from "../views/ChampionshipSelectView.vue";
import QrCodeView from "../views/QrCodeView.vue";
import RegisterFishView from "../views/RegisterFishView.vue";
import WeighingsView from "../views/WeighingsView.vue";
import RankingView from "../views/RankingView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/parameters",
    name: "parameters",
    component: ParametersView,
  },
  {
    path: "/syncData",
    name: "syncData",
    component: SyncDataView,
  },
  {
    path: "/championshipSelect",
    name: "championshipSelect",
    component: ChampionshipSelectView,
  },
  {
    path: "/qrCode",
    name: "qrCode",
    component: QrCodeView,
  },
  {
    path: "/registerFish",
    name: "registerFish",
    component: RegisterFishView,
  },
  {
    path: "/weighings",
    name: "weighings",
    component: WeighingsView,
  },
  {
    path: "/ranking",
    name: "ranking",
    component: RankingView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;
