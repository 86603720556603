<template>
  <div>
    <header class="bg-app-400 p-4">
      <span class="uppercase text-white font-medium">Selecione o campeonato</span>
    </header>

    <div v-if="currentChampionship == 0">
      <div v-if="Object.keys(championships).length > 0">
        <div class="flex flex-col championship__list">
          <div class="championship__list-item flex gap-8 p-4 border-b-[1px] border-b-app-200/50 items-center"
            v-for="(champ, index) in championships" :key="index" @click="selectChampionship(champ.id)">
            <img class="object-center	object-contain max-w-[100px] max-h-[100px]" :src="champ.image" />
            <span class="text-sm">{{ champ.description }}</span>
          </div>
        </div>
      </div>        
      <div v-else>
        <p class="text-center">Não há campeonatos neste período</p>
      </div>
    </div>

    <div v-else>
      <div v-if="Object.keys(stages).length > 0">
        <header class="bg-app-600 p-4">
          <span class="uppercase text-white font-medium">Selecione a etapa</span>
        </header>
        <div class="flex flex-col">
          <div class="flex gap-8 p-4 border-b-[1px] border-b-app-200/50 items-center" v-for="(stg, index) in stages"
            :key="index" @click="selectStage(stg.id)">
            <img class="object-center	object-contain max-w-[100px] max-h-[100px]" :src="stg.image" />
            <span class="text-sm">{{ stg.description }}</span>
            <span v-if="stg.issue !== false">⚠️</span>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-center">Não há etapas neste período</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChampionshipSelectView",
  methods: {
    async loadChampionships() {
      console.log("*** loadChampionships ***");
      this.championships = {};
      let store = this.$root.db
        .transaction("campeonatos")
        .objectStore("campeonatos");
      store.openCursor().addEventListener("success", (e) => {
        let cursor = e.target.result;
        if (cursor) {
          let item = {
            id: cursor.key,
            description: cursor.value.descricao,
            image: "data:image/png;base64," + cursor.value.imagem,
          };
          this.championships[cursor.key] = item;
          cursor.continue();
        } else {
          console.log(this.championships);
        }
      });
    },
    async loadStages() {
      console.log("*** loadStages ***");
      this.stages = {};
      if (this.currentChampionship == 0) return false;
      console.log("campeonato selecionado: " + this.currentChampionship);
      let store = this.$root.db
        .transaction(["etapas"], "readonly")
        .objectStore("etapas");
      const index = store.index("campeonato_id");
      index.getAll(this.currentChampionship).onsuccess = (event) => {
        for (let stg in event.target.result) {
          let issue = false;
          //verifica se tem fiscal associado ao coletor
          if(isNaN(event.target.result[stg].fiscal_id)) {
            issue = "A etapa não tem fiscal associado a este coletor";
          }
          //verifica se a etapa ocorre hoje
          let dt = new Date();
          let today = new Date(dt.getTime() - (dt.getTimezoneOffset() * 60000 )).toISOString().split("T")[0];
          if(event.target.result[stg].data != today) {
            issue = "A etapa não ocorre hoje";
          }
          let item = {
            id: event.target.result[stg].id,
            description: event.target.result[stg].descricao,
            image: "data:image/png;base64," + event.target.result[stg].imagem,
            issue: issue,
          };
          this.stages[event.target.result[stg].id] = item;
        }
        console.log(Object.keys(this.stages).length);
      };
    },
    selectChampionship(id) {
      console.log(id);
      this.currentChampionship = id;
      this.loadStages();
    },
    selectStage(id) {
      if(this.stages[id].issue !== false) {
        alert(this.stages[id].issue);
        return false;
      }
      this.currentStage = id;
      this.saveSelection();
    },
    async saveSelection() {
      this.$root.currentChampionship = this.currentChampionship;
      this.$root.currentStage = this.currentStage;
      let store = this.$root.db
        .transaction("parameters", "readwrite")
        .objectStore("parameters");
      store.put({ value: this.currentChampionship }, "currentChampionship");
      store.put({ value: this.currentStage }, "currentStage");
      //carrega dados do campeonato e etapa selecionados
      this.$root.currentChampionshipData = await this.$root.getChampionship(
        this.currentChampionship
      );
      this.$root.currentStageData = await this.$root.getStage(
        this.currentStage
      );
      this.$root.appStatus.championship = true;
      //vamos para a home
      this.$router.push({ path: "/" });
    },
  },
  mounted() {
    if (
       this.$root.appStatus.db &&
       this.$root.appStatus.parameters &&
       this.$root.appStatus.data
    ) {
      this.loadChampionships();
    } else {
      this.$router.push('/');
    }
  },
  data: () => ({
    championships: {},
    stages: {},
    currentChampionship: 0,
    currentStage: 0,
  }),
};
</script>
