<template>
	<div class="fixed top-0 left-0 z-50 w-full py-4
	transition-colors duration-500
	flex flex-wrap items-center justify-between
  border-slate-800/20 bg-app-700">

		<router-link to="/" class="max-w-[80px] ml-4">
			<img class="max-w-[80px] mx-auto h-8 w-20" src="https://www.fishtv.com/public/site/images/logos/fishtv.svg"
				alt="Logo Fish TV">
		</router-link>

		<div class="max-w-[45%]">
			<h3 v-if="$root.currentStageData != null" class="text-center text-slate-200 uppercase">{{ $root.currentStageData.descricao }}</h3>
			<h3 v-else class="text-center text-slate-200 uppercase">Nenhuma Etapa</h3>
		</div>

		<div class="flex items-center">
			<ToggleTheme />
			<Burger />
		</div>
	</div>

</template>

<script>

import Burger from './Burger.vue';
import ToggleTheme from './ToggleTheme.vue';

export default {
	components: {
		Burger,
		ToggleTheme
	},
}

</script>

<style>

</style>